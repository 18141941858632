import React from 'react'
import useDarkMode from 'use-dark-mode'
import tw, { css } from 'twin.macro'
import { MoonIcon } from '@heroicons/react/outline'
import { SunIcon } from '@heroicons/react/outline'

const DarkModeToggle = () => {
  const darkMode = useDarkMode(false)

  return (
    <div tw="pt-1.5 md:pt-0">
      {darkMode.value ? (
        <button
          tw="fill-current text-primary hover:text-stronger block"
          onClick={darkMode.disable}
        >
          <MoonIcon
            css={{
              width: 20,
              height: 20,
            }}
          />
        </button>
      ) : (
        <button
          tw="fill-current text-primary hover:text-stronger block"
          onClick={darkMode.enable}
        >
          <SunIcon
            css={{
              width: 20,
              height: 20,
            }}
          />
        </button>
      )}
    </div>
  )
}

export default DarkModeToggle
