import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { borderColor } from 'tailwindcss/defaultTheme'
import tw, { theme, css } from 'twin.macro'

export default connectSearchBox(({ refine, currentRefinement, onFocus }) => (
  <form>
    <input
      tw="block w-full text-base p-4 px-6 md:p-6 rounded-t-md focus:ring-0 bg-primary text-primary font-serif"
      css={{
        '&:focus': {
          outline: 'none',
          border: 'none',
          borderColor: 'none',
        },
        "[type='text']:focus": {
          border: 'none',
        },
        '&[type="text"]': {
          border: 'none',
          borderBottom: '1px solid',
          borderColor: `${theme`borderColor.accent`}`,
        },
      }}
      type="text"
      placeholder="Search keywords like “Design System” or “Grab”..."
      aria-label="Search"
      onChange={e => refine(e.target.value)}
      value={currentRefinement}
      onFocus={onFocus}
    />
  </form>
))
