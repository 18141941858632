import tw, { css } from 'twin.macro'

const stylesBase = css`
  .light-mode {
    --bg-primary: #ffffff;
    --bg-secondary: rgba(229, 231, 235, 0.5);
    --bg-lighter: #fafbfc;
    --bg-darker: #c7c8c9;

    --text-primary: #131415;
    --text-softer: #393a3b;
    --text-stronger: #0d0d0d;
    --text-cta: #ffffff;

    --color-primary: #001e9a;
    --color-accent: #0044cc;
    --color-border: #e5e7eb;

    --code-green: #226b34;
    --code-yellow: #8f9273;
    --code-teal: #61a3b1;
    --code-pink: #b25d8e;
  }
  .dark-mode {
    --bg-primary: #121212;
    --bg-secondary: rgba(34, 34, 34, 0.5);
    --bg-lighter: #37474f;
    --bg-darker: #0d0d0d;

    --text-primary: rgba(255, 255, 255, 0.87);
    --text-softer: #cccccc;
    --text-stronger: #ffffff;
    --text-cta: #131415;

    --color-primary: #62d4ed;
    --color-accent: #25c4e6;
    --color-border: #2c2c2c;

    --code-green: #50fa7b;
    --code-yellow: #f1fa8c;
    --code-teal: #8be9fd;
    --code-pink: #cc6aa2;
  }

  body {
    ${tw`subpixel-antialiased font-normal bg-primary text-primary scrollbar`}
  }

  body.dark-mode img.avatar {
    box-shadow: 0 0 6px 1px var(--color-accent);
  }

  @media (min-width: 768px) {
    body.dark-mode .card:hover {
      background-color: var(--bg-secondary);
    }
  }

  .avatar {
    img {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  a:focus,
  button:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--bg-primary), 0 0 0 4px var(--color-accent);
  }

  .btn a:focus {
    outline: none;
    box-shadow: 0 0.5px 0 2px var(--bg-primary), 0 0 0 4px var(--color-accent);
    border-radius: 99px;
  }

  button:focus:not(:focus-visible),
  a:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none !important;
  }

  @media (prefers-reduced-motion: no-preference) {
    a:focus,
    button:focus {
      transition: box-shadow 0.25s ease;
    }
    a:focus:not(:active),
    button:focus:not(:active) {
      box-shadow: 0 0 0 2px var(--bg-primary), 0 0 0 4px var(--color-accent);
    }
  }

  [data-reach-dialog-overlay] {
    background: var(--bg-secondary);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
  }

  [data-reach-dialog-content] {
    width: 50vw;
    margin: 10vh auto;
    background: var(--bg-primary);
    padding: 2rem;
    outline: none;
  }

  .gatsby-highlight {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .code-header {
    display: flex;
    justify-content: flex-end;
  }

  .language-name {
    padding-right: 1em;
    color: var(--code-pink);
  }

  .code-copy-button {
    color: var(--color-accent);
    background: none;
    border: none;
    cursor: pointer;
  }

  code {
    padding: 2px 6px;
    color: var(--text-primary);
  }

  code[class*='language-'],
  pre[class*='language-'] {
    padding: 0;
    color: var(--text-primary);
    background: none;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    font-size: large;
  }

  pre[class*='language-'] {
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }

  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    background: var(--bg-secondary);
  }

  /* Inline code */
  :not(pre) > code[class*='language-'] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  }

  .line-number {
    color: var(--text-softer);
    opacity: 0.3;
    padding-right: 2rem;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: var(--text-softer);
    opacity: 0.5;
  }

  .token.punctuation {
    color: var(--text-primary);
  }

  .namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.tag,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #ff79c6;
  }

  .token.boolean,
  .token.number {
    color: #bd93f9;
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: var(--code-green);
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string,
  .token.variable {
    color: var(--text-primary);
  }

  .token.atrule,
  .token.attr-value,
  .token.function,
  .token.class-name {
    color: var(--code-yellow);
  }

  .token.keyword {
    color: var(--code-teal);
  }

  .token.regex,
  .token.important {
    color: #ffb86c;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  .cookie-button {
    padding: 0.125rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 99px;
    border: 1px solid var(--color-border);
    @media (max-width: 768px) {
      width: 100%;
      padding: 0.5rem;
    }
  }

  .cookie-decline-button {
    padding: 0.125rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 99px;
    border: 1px solid var(--color-border);
    @media (max-width: 768px) {
      width: 100%;
      padding: 0.5rem;
    }
  }

  .cookie-button-wrapper {
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  }

  .cookie-container {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.75rem;
    border-top: 1px solid var(--color-border);
    background-color: var(--bg-primary);
    border-radius: 4;
    justify-content: center;
    align-items: center;
    z-index: 50;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      padding-bottom: 2rem;
    }
  }

  .cookie-content {
    margin-right: 2rem;
  }

  .tooltip-trigger {
    width: 24px;
    height: 24px;
    font-size: 1rem;
    line-height: 1.25;
    text-align: center;
    background-color: var(--bg-primary);
    border-radius: 999px;
    border: 1px solid var(--color-border);
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
  }

  .toggled .tooltip-content {
    width: min(350px, 35vw);
    padding: 0.75rem;
    margin-top: 0.5rem;
    background-color: var(--bg-primary);
    border-radius: 4px;
    border: 1px solid var(--color-border);
    box-shadow: 0 2px 32px 2px var(--bg-secondary);
  }

  @-webkit-keyframes sonarEffect {
    from {
      opacity: 0.5;
    }
    to {
      transform: scale(2.5);
      opacity: 0;
    }
  }
`

export default stylesBase
