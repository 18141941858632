import React from 'react'
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav'
import Header from './header'
import Footer from './footer'
import tw, { GlobalStyles, css } from 'twin.macro'
import { Global } from '@emotion/react'

import stylesBase from './stylesBase'

const Layout = ({ children, siteTitle, ...rest }) => (
  <div {...rest}>
    <GlobalStyles />
    <Global styles={stylesBase} />
    <Header siteTitle={siteTitle} SkipNavLink={SkipNavLink} />
    <SkipNavContent>{children}</SkipNavContent>
    <Footer />
  </div>
)

export default Layout
