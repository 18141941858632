import React from 'react'
import { Dialog, DialogOverlay, DialogContent } from '@reach/dialog'
import Search from './search'
import tw, { theme, css } from 'twin.macro'
import { SearchIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/outline'
import useKeyboardShortcut from 'use-keyboard-shortcut'

const searchIndices = [
  { name: `Notes`, title: `Notes` },
  { name: `Pages`, title: `Pages` },
]

function Modal(props) {
  const [showDialog, setShowDialog] = React.useState(false)
  const buttonRef = React.useRef()
  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  useKeyboardShortcut(['Meta', 'K'], () => setShowDialog(true), { overrideSystem: false })

  return (
    <div tw="justify-self-end pt-1.5 md:pt-0">
      <button tw="flex flex-row items-stretch space-x-1" onClick={open}>
        <SearchIcon
          tw="self-center"
          css={{
            width: 20,
            height: 20,
          }}
        />
        <div
          tw="hidden md:block text-xs font-light self-center border px-1 rounded bg-lighter opacity-70 text-softer"
          css={{
            borderColor: `${theme`backgroundColor.darker`}`,
          }}
        >
          <kbd>⌘</kbd>
          <kbd>K</kbd>
        </div>
      </button>
      <DialogOverlay tw="z-50 flex flex-col-reverse" isOpen={showDialog} onDismiss={close}>
        <DialogContent
          tw="block bg-white rounded-lg transform transition-all sm:align-middle w-full mt-16 mb-0 sm:my-16 max-h-screen overflow-hidden p-0 shadow-lg"
          css={{
            maxWidth: 'min(650px, 100%)',
          }}
        >
          <Search indices={searchIndices} initialFocusRef={buttonRef} ref={buttonRef} />
        </DialogContent>
        <div
          tw="mx-auto block relative h-8"
          css={{
            width: 'min(808px, 100% - 24px)',
          }}
        >
          <div tw="absolute top-3 right-2 md:right-0 z-30">
            <button tw="w-max text-xs inline-flex flex-col" onClick={() => setShowDialog(false)}>
              <div tw="rounded-full p-1 w-max shadow-sm inline-flex bg-lighter">
                <XIcon
                  tw="fill-current"
                  css={{
                    width: 28,
                    height: 28,
                  }}
                  tw="inline-flex"
                />
              </div>
              <kbd
                tw="w-max p-1 hidden md:inline-flex font-mono tracking-tight leading-none mt-2 mx-auto bg-lighter shadow-sm rounded"
                css={{
                  height: 'fit-content',
                  color: `${theme`textColor.softer`}`,
                }}
              >
                esc
              </kbd>
            </button>
          </div>
        </div>
      </DialogOverlay>
    </div>
  )
}

export default Modal
