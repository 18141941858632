import { Link } from 'gatsby'
import React from 'react'
import tw, { theme, css } from 'twin.macro'
import { Match } from '@reach/router'
import CookieConsent, { Cookies } from 'react-cookie-consent'
// import { useLocation } from '@reach/router' 
// import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const StyledFooter = ({ path, children }) => (
  <Match path="/">
    {props =>
      props.match ? <div>{children}</div> : <div tw="mt-24 md:mt-32">{children}</div>
    }
  </Match>
)

const Footer = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCookieBar: false,
    }

    if (!Cookies.get('gatsby-gdpr-google-analytics')) {
      this.state.showCookieBar = true
    }
  }

  render() {
    return (
      <>
        <footer
          tw="bg-primary w-full border-t py-4 pb-10 md:py-6 relative z-10"
          css={{
            borderColor: `${theme`borderColor.accent`}`,
          }}
        >
          <div
            tw="mx-6 sm:ml-8 md:mx-auto flex flex-col justify-between md:flex-row gap-y-2"
            css={{
              width: 'min(1024px, calc(100% - 48px))',
            }}
          >
            <div tw="flex prose prose-sm sm:prose md:prose pt-4 md:py-0 auto-cols-fr">
              <p tw="text-sm text-softer">
                Copyright &copy; 2009 – {new Date().getFullYear()} Ajmal Afif
              </p>
            </div>
            <div tw="flex flex-col md:flex-row gap-y-3 gap-x-4 my-4 md:my-0">
              <button
                tw="text-sm text-softer cursor-pointer underline inline-flex"
                onClick={() => {
                  Cookies.remove('gatsby-gdpr-google-analytics')
                  this.setState({ showCookieBar: true })
                }}
              >
                Cookie Settings
              </button>
              <Link
                tw="text-sm text-softer cursor-pointer underline inline-flex"
                to="/uses"
              >
                Uses
              </Link>
              <Link
                tw="text-sm text-softer cursor-pointer underline inline-flex"
                to="/now"
              >
                Now
              </Link>
              <Link
                tw="text-sm text-softer cursor-pointer underline inline-flex"
                to="/reviews"
              >
                Reviews
              </Link>
            </div>
            <div tw="flex space-x-6 md:justify-end py-4 md:py-0">
              <a
                href="https://linkedin.com/in/ajmalafif"
                target="_blank"
                rel="noopener noreferrer"
                title="LinkedIn"
                tw="text-center md:text-right"
                css={{
                  color: '#c0c0c0',
                  ':hover': {
                    color: `${theme`colors.primary`}`,
                  },
                }}
              >
                <svg
                  fill="currentColor"
                  role="img"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>LinkedIn icon</title>
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
                </svg>
              </a>
              <a
                href="https://twitter.com/ajmalafif"
                target="_blank"
                rel="noopener noreferrer"
                title="LinkedIn"
                tw="text-center md:text-right"
                css={{
                  color: '#c0c0c0',
                  ':hover': {
                    color: `${theme`colors.primary`}`,
                  },
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M24 4.37a9.6 9.6 0 0 1-2.83.8 5.04 5.04 0 0 0 2.17-2.8c-.95.58-2 1-3.13 1.22A4.86 4.86 0 0 0 16.61 2a4.99 4.99 0 0 0-4.79 6.2A13.87 13.87 0 0 1 1.67 2.92 5.12 5.12 0 0 0 3.2 9.67a4.82 4.82 0 0 1-2.23-.64v.07c0 2.44 1.7 4.48 3.95 4.95a4.84 4.84 0 0 1-2.22.08c.63 2.01 2.45 3.47 4.6 3.51A9.72 9.72 0 0 1 0 19.74 13.68 13.68 0 0 0 7.55 22c9.06 0 14-7.7 14-14.37v-.65c.96-.71 1.79-1.6 2.45-2.61z" />
                </svg>
              </a>
              <a
                href="/rss.xml"
                target="_blank"
                rel="noopener noreferrer"
                title="LinkedIn"
                tw="text-center md:text-right"
                css={{
                  color: '#c0c0c0',
                  ':hover': {
                    color: `${theme`colors.primary`}`,
                  },
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M4 11a9 9 0 0 1 9 9"></path>
                  <path d="M4 4a16 16 0 0 1 16 16"></path>
                  <circle cx="5" cy="19" r="1"></circle>
                </svg>
              </a>
            </div>
          </div>
        </footer>
        {this.state.showCookieBar && (
          <CookieConsent
            debug={true}
            location="bottom"
            cookieName="gatsby-gdpr-google-analytics"
            sameSite="strict"
            flipButtons={true}
            disableStyles={true}
            disableButtonStyles={true}
            buttonText="Enable"
            enableDeclineButton={true}
            declineButtonText="Disable"
            buttonClasses="cookie-button"
            declineButtonClasses="cookie-decline-button"
            buttonWrapperClasses="cookie-button-wrapper"
            containerClasses="cookie-container"
            contentClasses="cookie-content"
            expires={365}
            onAccept={() => {
              this.setState({ showCookieBar: false })
            }}
            onDecline={() => {
              this.setState({ showCookieBar: false })
            }}
          >
            This site uses cookies to enhance overall user experience. You can enable or disable
            cookies at any time from the footer link.
          </CookieConsent>
        )}
      </>
    )
  }
}

export default Footer
